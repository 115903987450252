<div appScrollspy [spiedTags]="['SECTION']">
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/7.png)" id="home">
    <div innerHTML="{{'covid19' | translate}}"></div>
  </section>
  <ng-template #videocontent let-modal class="video-modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <youtube-player [videoId]="'99BMvyKjZqo'" height="450" width="780">
      </youtube-player>
    </div>
  </ng-template>
</div>