<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <a class="navbar-brand logo" routerLink="/home">
      <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="36" />
    </a>
    <button class="btn-mobile" (click)="goToDemo()">
      Jadwalkan Demo
    </button>
    <button id="navbar-t" class="navbar-toggler collapsed" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span> </span>
      <span> </span>
      <span> </span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
        <li class="nav-item dropdown" style="position: static;">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.feature' | translate}}
          </a>
          <div class="dropdown-menu feature-menu">
            <div class="container">
              <div class="row mb-3" style="margin-right: -120px;">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-clipboard icon-feature-1"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/transaction-analysis-report">
                        <div innerHTML="{{'header.feature1' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-book-multiple icon-feature-2"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/accounting-process">
                        <div innerHTML="{{'header.feature2' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-bank icon-feature-3"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/cash-bank">
                        <div innerHTML="{{'header.feature3' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-factory icon-feature-4"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/procurement">
                        <div innerHTML="{{'header.feature4' | translate}}"></div>
                      </a>
                    </div>
                  </div>

                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-currency-usd icon-feature-5"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/sales">
                        <div innerHTML="{{'header.feature5' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-package-variant-closed icon-feature-6"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/inventory">
                        <div innerHTML="{{'header.feature6' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-chart-pie icon-feature-7"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/ar-ap-module">
                        <div innerHTML="{{'header.feature7' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-city icon-feature-8"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/fixed-assets">
                        <div innerHTML="{{'header.feature8' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-autorenew icon-feature-9"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/forex-gain-loss">
                        <div innerHTML="{{'header.feature9' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-finance icon-feature-10"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/security">
                        <div innerHTML="{{'header.feature10' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-security icon-feature-11"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/cloud-services">
                        <div innerHTML="{{'header.feature11' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <i class="mdi mdi-cloud-check icon-feature-12"></i>
                    </div>
                    <div class="col-lg-10">
                      <a class="dropdown-item" (click)="hideMenu()" routerLink="/recurring-journal">
                        <div innerHTML="{{'header.feature12' | translate}}"></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="nav-item" [ngClass]="{'dropdown': !isMobile}">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.solution' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/manufacture-companies">
                <i class="mdi mdi-factory h3 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #3e87db;"></i> <strong
                  class="font-size-17"><b>{{'header.solution1' | translate}}</b></strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.solution1Sub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/construction-companies">
                <i class="mdi mdi-crane h2 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #46978d"></i> <strong
                  class="font-size-17">{{'header.solution3' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.solution3Sub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/distribution-companies">
                <i class="mdi mdi-warehouse h3 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #fd9644"></i> <strong
                  class="font-size-17">{{'header.solution2' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.solution2Sub' | translate}}
                </span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/service-companies">
                <i class="mdi mdi-hammer-screwdriver h3 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #6557d6"></i>
                <strong class="font-size-17">{{'header.solution4' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">
                  {{'header.solution4Sub' | translate}}
                </span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" [ngClass]="{'dropdown': !isMobile}">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false">
            {{'header.resources' | translate}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/about">
                <i class="mdi mdi-book-open-page-variant h3 mr-3" [ngClass]="{'ml-2': isMobile}"
                  style="color: #fd9644"></i> <strong class="font-size-17">{{'header.about' | translate}}</strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.aboutSub' | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/strategy-Implementasi">
                <i class="mdi mdi-sign-text h3 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #46978d"></i> <strong
                  class="font-size-17">{{'header.strategy' | translate}}</strong>
                <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.strategySub' | translate}}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li>
              <a class="dropdown-item" (click)="hideMenu()" routerLink="/clients">
                <i class="mdi mdi-account-group h3 mr-3" [ngClass]="{'ml-2': isMobile}" style="color: #3e87db;"></i>
                <strong class="font-size-17">{{'header.customer' | translate}}</strong> <br>
                <span *ngIf="!isMobile" class="font-size-14">{{'header.customerSub' | translate}}
                  &nbsp;&nbsp;</span>
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a routerLink="/pricing" (click)="hideMenu()" class="nav-link">{{'header.price' | translate}}</a>
        </li> -->
        <li class="nav-item">
          <a routerLink="/contact" (click)="hideMenu()" class="nav-link">{{'header.contact' | translate}}</a>
        </li>
        <li class="line"></li>
        <li class="nav-item" [ngClass]="{'dropdown': !isMobile}">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-expanded="false" style="border-bottom: none;">
            <span *ngIf="savedLang === 'id' || !savedLang">
              <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
            </span>
            <span *ngIf="savedLang === 'en'">
              <img src="assets/images/us-flag.png" width="13%" class="mr-2"> English
            </span>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="flag" (click)="onChangeLang('id')" *ngIf="savedLang === 'en'">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/indonesia-flags.png" width="13%" class="mr-2"> Indonesia
                </strong>
                <br>
              </a>
            </li>
            <li class="flag" (click)="onChangeLang('en')" *ngIf="savedLang === 'id' || !savedLang">
              <a class="dropdown-item"> <strong [ngClass]="{'ml-3': isMobile}" class="font-size-15">
                  <img src="assets/images/us-flag.png" width="13%" class="mr-2"> English</strong>
                <br>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav navbar-center">
        <li class="nav-item">
          <button class="btn btn-primary btn-sm btn-pc" (click)="goToDemo()">
            {{'header.demo' | translate}}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>