<div class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);" id="features">
  <div class="container mt-5">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-5 mt-4">
            <p class="font-weight-bold">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'features1.transAnalys' |
              translate}}
            </p>
            <hr style="margin-top: -10px;">
            <div class="mb-5" *ngIf="isMobile">
              <img src=" assets/images/feature-undraw/feature-1.jpg" class="w-100">
            </div>
            <h2 class="text-dark font-weight-bold mb-3 font-mobile">
              <div innerHTML="{{'features1.transAnalysisheader' | translate}}"></div>
            </h2>
            <p class="font-size-16">
              {{'features1.transAnalysisSub' | translate}}
            </p>
          </div>
        </div>
        <div class="col-lg-6" style="margin-top: 5em;" *ngIf="!isMobile">
          <img src=" assets/images/feature-undraw/feature-1.jpg" class="w-100">
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);">
  <div class="container">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/info-sales.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/calendar-monitoring.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/financial-report.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-text-box-search"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features1.carousel1' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features1.carousel1Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-calendar-search"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">Calendar Monitoring</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features1.carousel2Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-chart"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features1.carousel3' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features1.carousel3Sub' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote2></app-block-quote2>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div *ngIf="isMobile">
          <img src="assets/images/ss-app/financial-report-3.png" class="img-fluid d-block w-100">
        </div>

        <div innerHTML="{{'features1.detailheader1' | translate}}"></div>
        <p class="font-size-15 mb-4">
          {{'features1.detailSub1' | translate}}
        </p>
        <ul style="margin-left: -1px;">
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.financial1'
                | translate}}</b>
              (Income Statement)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features1.financial2"
                | translate}}</b> (Balance
              Sheet)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.financial3'
                | translate}}</b>
              (Cost of Goods Sold)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.financial4'
                | translate}}</b>
              (Cash Flow)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features1.financial5Bold' | translate}}</b> {{"features1.financial5" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features1.financial6"
                | translate}}</b> (Ratio
              Analysis)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features1.financial7"
                | translate}}</b>
              (Budget Comparation)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Export </b>
              {{'features1.financial8' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Report
                Setup</b> {{"features1.financial9" | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6" *ngIf="!isMobile">
        <div class="mt-5" style="margin-right: -4em;">
          <br>
          <img src="assets/images/ss-app/financial-report-3.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0" style="margin-left: -4em;" *ngIf="!isMobile">
          <img src="assets/images/ss-app/gl-report.png" class="img-fluid d-block w-100">
        </div>
        <div class="mb-4 mb-lg-0" *ngIf="isMobile">
          <img src="assets/images/ss-app/gl-report.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b><span class="text-primary">General Ledger </span>Report</b>
          <br>
        </h3>
        <p class="font-size-15 mb-4">
          {{'features1.detailSub2' | translate}}
        </p>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl1Bold' |
                translate}}</b> (Listing by CoA)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl2Bold' |
                translate}}</b> (Customer Subsidiary)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl3Bold' |
                translate}}</b> (Supplier Subsidiary)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl4Bold' |
                translate}}</b> (Journal Listing)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b> {{'features1.gl5Bold' |
                translate}}
              </b> (Trial
              Balance)
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl6Bold' |
                translate}}</b> {{'features1.gl6' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.gl7Bold' |
                translate}}</b> {{'features1.gl7' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Export</b>
              {{'features1.gl8' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Report Setup</b>
              {{'features1.gl9' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/multi-coa.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features1.detailheader3' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.coa1Bold' |
                translate}}</b>
              {{'features1.coa1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features1.coa2Bold' |
                translate}}</b>
              {{'features1.coa2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5" style="margin-right: -5em;">
          <img src="assets/images/ss-app/multi-coa.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0" style="margin-left: -5em;" *ngIf="!isMobile">
          <img src="assets/images/ss-app/cost-centre.png" class="img-fluid d-block w-100">
        </div>
        <div class="mb-4 mb-lg-0" *ngIf="isMobile">
          <img src="assets/images/ss-app/cost-centre.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features1.detailheader4' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features1.costCentre1Bold' | translate}}</b>
              {{'features1.costCentre1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features1.costCentre2Bold' | translate}}</b>
              {{'features1.costCentre2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-report-analysis></app-report-analysis>