<div class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-5 mt-3">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'softImp.startHeader' | translate}}
          </p>
          <hr style="margin-top: -10px;">
          <div *ngIf="isMobile">
            <img src="assets/images/ss-app/strategy.png" class="d-block img-fluid">
          </div>
          <div innerHTML="{{'softImp.startTitle' | translate}}"></div>
          <p class="font-size-16">
            {{'softImp.startContent' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6 mt-5 img-feature" *ngIf="!isMobile">
        <img src="assets/images/ss-app/strategy.png" class="d-block img-fluid">
      </div>
    </div>

  </div>
</div>

<section style="background-image: url(assets/images/bg/17.png);">
  <div class="container">
    <div class="mb-5 img-feature">
      <img src="assets/images/ss-app/home-strategy.png" class="d-block img-fluid">
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -7em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div *ngIf="isMobile">
          <img src="assets/images/ss-app/strategy2.png" class="img-fluid d-block w-100">
        </div>

        <p class="mb-1"><i class="mdi mdi-cogs h1" style="color: #1565c0;"></i></p>
        <h2 class="font-weight-bold line-height-1_4 mb-4">
          <b>
            Project Preparation <span class="text-primary"> (Persiapan Awal Proyek)</span>
          </b>
        </h2>
        <p>
          {{'softImp.content1' | translate}}
        </p>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub1' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub2' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub3' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub4' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub5' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub6' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content1Sub7' | translate}}</b>
            </p>
          </li>
        </ul>
        <br>
        <p>
          {{'softImp.content1Sub8' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div>
          <img src="assets/images/ss-app/strategy2.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -7em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/blueprint.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <p class="mb-1"> <i class="mdi mdi-script-text h1" style="color: #ee5253;"></i> </p>
        <h2 class="font-weight-bold line-height-1_4 mb-4"><b>
            <span class="text-primary">Business</span> Blueprint
          </b>
        </h2>
        <p class="mb-2 font-size-15">
          {{'softImp.content2' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/realization.png" class="img-fluid d-block w-100">
      </div>

      <div class="col-lg-5">
        <p class="mb-1"><i class="mdi mdi-sign-text h1" style="color: #02bbb2;"></i></p>
        <h2 class="font-weight-bold line-height-1_4 mb-4"><b>
            Realization<span class="text-primary"> (Pengerjaan Modul Software)</span>
          </b></h2>
        <div innerHTML="{{'softImp.content3' | translate}}"></div>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/realization.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/preparation.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <p class="mb-1"><i class="mdi mdi-book-multiple h1" style="color: #6557d6;"></i></p>
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Final Preparation
            <span class="text-primary"> (Persiapan Akhir Menuju Go Live)</span>
          </b>
        </h3>
        <div innerHTML="{{'softImp.content4' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content4Sub1' | translate}}</b>
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content4Sub2' | translate}}</b>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content4Sub3' | translate}}</b>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content4Sub4' | translate}}</b>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'softImp.content4Sub5' | translate}}</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -1em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div *ngIf="isMobile">
          <img src="assets/images/ss-app/support.png" class="img-fluid d-block w-100">
        </div>
        <p class="mb-1"><i class="mdi mdi-monitor h1" style="color: #fd9644;"></i></p>
        <h2 class="font-weight-bold line-height-1_4 mb-4"><b>
            <span class="text-primary">Go Live</span> & Support
          </b></h2>
        <div innerHTML="{{'softImp.content5' | translate}}"></div>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/support.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>