<div class="hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="container mt-5">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <h2 class="mb-3">
          <div innerHTML="{{'customer.title' | translate}}"></div>
        </h2>
        <p class="mb-5 pb-5 font-size-17">
          {{'customer.content' | translate}}
        </p>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <img src="assets/images/customer.png" class="w-100">
      </div>
    </div>
  </div>
</div>
<div class="section" style="background-image: url(assets/images/bg/17.png)" id="home">
  <div class="container" style="margin-top: -50px;">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h2>SOFICloud <span class="text-primary">Customer</span></h2>
        </div>
        <hr style="border: 3px solid #1565c0; margin-top: -24px; width: 5em;">
      </div>
    </div>
    <br>
    <div class="pc align-items-center mt-3">
      <div class="row mb-5 logo">
        <div class="col-md-2">
          <img src="assets/images/customer/1.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/2.jpg" style="width: 60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/3.png" style="width: 120px;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/4.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/5.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/6.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/7.jpg" style="width: 55%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/8.png" style="width:130px;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/9.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/10.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/11.jpg" style="width: 75%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/12.jpg" style="width:120px;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/13.png" style="width:45%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/14.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/15.jpg" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/16.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/17.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/18.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/19.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/20.png" style="width:95%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/21.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/22.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/23.jpeg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/24.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/25.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/26.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/27.jpeg" style="width: 95%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/28.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/29.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/30.jpg" style="width: 62%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/31.png" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/32.png" style="width: 60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/33.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/34.png" style="width: 60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/35.jpg" style="width:60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/36.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/37.jpeg" style="width: 40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/38.jpeg" style="width: 43%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/39.jpeg" style="width: 35%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/40.jpeg" style="width: 100%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/41.jpg" style="width:110px;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/42.jpeg" style="width: 70%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/43.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/44.jpeg" style="width: 75%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/45.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/46.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/47.jpeg" style="width: 65%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/48.jpeg" style="width: 65%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/49.jpeg" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/50.jpeg" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/51.jpeg" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/52.png" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/53.jpeg" style="width: 100%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/54.jpeg" style="width: 60%;">
        </div>
      </div>
    </div>

    <div class="mobile align-items-center mt-3">
      <div class="text-center mb-5">
        <img src="assets/images/customer/1.png" class="mr-5">
        <img src="assets/images/customer/2.jpg">
      </div>
      <div class="text-center mb-5">
        <img src="assets/images/customer/3.png" class="mr-5">
        <img src="assets/images/customer/4.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/5.png" class="mr-5">
        <img src="assets/images/customer/6.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/7.jpg" class="mr-5">
        <img src="assets/images/customer/8.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/9.png" class="mr-5">
        <img src="assets/images/customer/10.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/11.jpg" class="mr-5">
        <img src="assets/images/customer/12.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/13.png" class="mr-5">
        <img src="assets/images/customer/14.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/15.jpg" class="mr-5">
        <img src="assets/images/customer/16.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/17.png" class="mr-5">
        <img src="assets/images/customer/18.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/19.jpg" class="mr-5">
        <img src="assets/images/customer/20.png" style="width: 37%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/21.png" class="mr-5">
        <img src="assets/images/customer/22.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/23.jpeg" class="mr-5">
        <img src="assets/images/customer/24.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/25.png" class="mr-5">
        <img src="assets/images/customer/26.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/27.jpeg" class="mr-5" style="width: 37%;">
        <img src="assets/images/customer/28.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/29.jpg" class="mr-5">
        <img src="assets/images/customer/30.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/31.png" class="mr-5">
        <img src="assets/images/customer/32.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/33.jpg" class="mr-5">
        <img src="assets/images/customer/34.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/35.jpg" class="mr-5">
        <img src="assets/images/customer/36.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/37.jpeg" class="mr-5">
        <img src="assets/images/customer/38.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/39.jpeg" class="mr-5">
        <img src="assets/images/customer/40.jpeg" style="width: 37%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/41.jpg" class="mr-5">
        <img src="assets/images/customer/42.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/43.png" class="mr-5">
        <img src="assets/images/customer/44.jpeg" style="width: 33%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/45.png" class="mr-5">
        <img src="assets/images/customer/46.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/47.jpeg" class="mr-5">
        <img src="assets/images/customer/48.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/49.jpeg" class="mr-5">
        <img src="assets/images/customer/50.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/51.jpeg" class="mr-5">
        <img src="assets/images/customer/52.png">
      </div>

      <div class="text-center">
        <img src="assets/images/customer/53.jpeg" class="mr-5">
        <img src="assets/images/customer/54.jpeg">
      </div>
    </div>
  </div>
</div>

<!-- <app-testimonial></app-testimonial> -->