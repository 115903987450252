import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  isMobile: boolean;

  constructor() { }

  ngOnInit(): void {
    if (window.screen.width <= 585) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
}
