<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/8.png)" id="home">
    <div class="container">
      <div class="row">
        <div class="text-center">

          <div innerHTML="{{'customize.customizeHeader' | translate}}"></div>

          <div innerHTML="{{'customize.customizeSub' | translate}}"></div>

          <hr style="border: 2px solid #1565c0; width: 10em;">

          <img src="assets/images/customize.jpg" alt="" class="img-fluid d-block mx-auto" width="70%">

        </div>

        <div class="mt-5">
          <div innerHTML="{{'customize.customizeContent' | translate}}"></div>
        </div>
      </div>
    </div>
  </section>
</div>