<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/8.png)" id="home">
    <div class="container">
      <div class="row">
        <div class="text-center">

          <h1 class="mb-3"><b><span class="text-primary">Training</span> & Re-<span
                class="text-primary">Implementation</span></b></h1>


          <div innerHTML="{{'training.training1' | translate}}"></div>

          <hr style="border: 2px solid #1565c0; width: 20em;">

          <img src="assets/images/training.jpg" class="img-fluid d-block mx-auto mt-4" width="80%">
        </div>
        <div class="mt-5" innerHTML="{{'training.training2' | translate}}"></div>
      </div>
    </div>
  </section>

  <!-- Hero End -->

</div>