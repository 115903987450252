<div appScrollspy [spiedTags]="['SECTION']">
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/7.1.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold mb-4 text-center">Terms and <span
          class="text-primary">Conditions</span>
      </h1>
      <hr style="width: 25%; border: 2px solid #1565c0;">
      <br><br><br>
      <div innerHTML="{{'termCondition.term1' | translate}}"></div>

      <div innerHTML="{{'termCondition.term2' | translate}}"></div>

      <div innerHTML="{{'termCondition.term3' | translate}}"></div>

      <div innerHTML="{{'termCondition.term4' | translate}}"></div>

      <div innerHTML="{{'termCondition.term5' | translate}}"></div>

      <div innerHTML="{{'termCondition.term6' | translate}}"></div>

      <div innerHTML="{{'termCondition.term7' | translate}}"></div>

      <div innerHTML="{{'termCondition.term8' | translate}}"></div>

      <div innerHTML="{{'termCondition.term9' | translate}}"></div>

      <div innerHTML="{{'termCondition.term10' | translate}}"></div>

      <div innerHTML="{{'termCondition.term11' | translate}}"></div>

      <div innerHTML="{{'termCondition.term12' | translate}}"></div>
    </div>
  </section>
</div>