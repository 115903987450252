import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forex-gain-loss',
  templateUrl: './forex-gain-loss.component.html',
  styleUrls: ['./forex-gain-loss.component.scss']
})
export class ForexGainLossComponent implements OnInit {
  @ViewChild('carouselStart', { static: true }) carousel: NgbCarousel;
  indexCarousel = "ngb-slide-0";
  savedLang = localStorage.getItem('lang');
  isMobile: boolean;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.setLanguage()

    if (window.screen.width <= 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }

  getIndexCarousel(slide) {
    this.indexCarousel = slide.current;
  }

  goToCarousel(index) {
    this.carousel.select(index);
  }

  private setLanguage() {
    this.translate.addLangs(['id', 'en']);
    this.translate.setDefaultLang(!this.savedLang ? 'id' : this.savedLang);
  }
}
