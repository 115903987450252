<section class="section" style="background-image: url(assets/images/bg/9.png)">
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h1 class="font-mobile">
            <div innerHTML="{{'price.title' | translate}}"></div>
          </h1>
          <p class="font-size-17">{{'price.subtitle' | translate}}</p>
          <hr style="border: 3px solid #1565c0; width: 40%;">
        </div>
      </div>
    </div>
    <div class="price-plan-wrapper">
      <div class="row">
        <div class="col-lg-5 col-md-6">
          <div class="pricing-table-8 active baseBlock">
            <div class="price-header">
              <!-- <span class="popular">Best</span> -->
              <h3 class="title">ENTERPRISE</h3>
              <div class="price">
                <span class="dollar">Rp</span>5,000.000
                <div class="month">Cloud Service/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>Rp
                    500.000</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Bill of Material & Routing</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Production Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Construction Module</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary' | translate}} <br> <b>Rp 80.000.000</b></span>
            <div class="mt-3">
              <b>Full Software <span class="text-primary">Maintenance Services</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-1 col-md-6">
          <div class="pricing-table-8 baseBlock">
            <div class="price-header">
              <h3 class="title">SPECIAL PACKAGE</h3>
              <div class="price">
                {{'header.contact' | translate}}
                <div class="month">Cloud Service/User/{{'price.month' | translate}}</div>
              </div>
            </div>
            <div class="container font-weight-bold text-left font-size-13">
              <hr>
              <div class="text-center">
                <span class="font-size-14 font-weight-normal">{{'price.addUser' | translate}} <br> <b>{{'header.contact'
                    | translate}}</b></span>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> General Ledger</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Account Payable</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i>Account Receivable</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Fixed
                    Asset</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Cash & Bank</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Procurement</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Service</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Inventory</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Sales</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Assembly</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Budgeting</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Dashboard</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Bill of Material & Routing</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Production Module</span>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Construction Module</span>
                </div>
                <div class="col-xl-6">
                  <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Project Costing</span>
                </div>
              </div>
              <hr>
              <span><i class="mdi mdi-check-circle mr-1 text-success"></i> Standart Costing</span>
              <hr>
            </div>
            <span>{{'price.salary2' | translate}} <br> <b>{{'header.contact' | translate}}</b></span>
            <div class="mt-3">
              <b>++ Full <span class="text-primary">Customize Module</span></b>
            </div>
            <div class="price-footer">
              <a class="order-btn"
                href="https://api.whatsapp.com/send?phone=628117774744&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F">Contact
                Us</a>
            </div>
          </div>
        </div>
       
        
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">

    <p class="font-weight-bold font-size-16">
      <i class="mdi mdi-book-multiple h2 mr-1 align-middle" style="color: #3e87db;"></i> General Ledger
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> General Journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Recurring Journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Template Journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Transaction Listing by CoA (Ledger)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Transaction Listing by Journal Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Trial Balance Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Balance Sheet
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Income Statement
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Budgeting
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Recurring Maturity Schedule Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Currency
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Home Currency
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Division
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Department
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Level Chart of Account (9
          Level)
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-4': isMobile}">
      <i class="mdi mdi-cash h1 mr-1 align-middle" style="color: #3f51b5;"></i>Account Payable (Hutang Pemasok)
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/P Journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/P Debit Note
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Receive Invoice (related with purchase module)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Invoice Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Statement of Account Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Aging Analys Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Due Invoice Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Overdue Invoice Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/P Debit Note Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Transaction Listing by Supplier Report <span class="distance">
            (subsidiary ledger)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Auto Forex A/P Revaluation & Realized <span class="distance">
            (Journal
            Calculation &Forex History Report)</span>
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-credit-card-outline h2 mr-1 align-middle" style="color: #c43eae;"></i> Account Receivable
      (Piutang Pelanggan)
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/R Journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/R Debit Note
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Invoice (related with sales module)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Invoice Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Statement of Account Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Aging Analys Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Due Invoice Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Overdue Invoice Report
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> A/R Debit Note Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Transaction Listing by Customer Report <span class="distance">
            (subsidiary ledger)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Auto Forex A/R Revaluation & Realized <span class="distance">
            (Journal Calculation & Forex History Report)
          </span>
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-city h2 mr-1 align-middle" style="color: #fd9644;"></i> Fixed Asset (Aktiva Tetap)
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Fixed Asset Group and location
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Fixed Asset Detail
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Display Fixed Asset Foto
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Many type of Calculation method (Straight line, <span
            class="distance"> Double declining, Sum of years digits, Manual)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Auto depreciation expense journal
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Allocated depreciaton expenses to many <span class="distance">
            division, department, or project</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Write off fixed assets facility
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Facility to add sub of fixed asset
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Fixed Asset Listing (All,
          Registered, and Disposed <span class="distance"> Fixed Asset)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Fixed Asset Depreciation Report by Asset Group <span
            class="distance">
            (summary & detail)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Fixed Asset Depreciation Report by Cost Centre <span
            class="distance"> and Location (summary & detail)</span>
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-bank h3 mr-1 align-middle" style="color: #00bcd4;"></i>Cash & Bank
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Billing Payment
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Payment Cheque Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Payment Journal Voucher
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Billing Receive
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Receive Cheque Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Receive Journal Voucher
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Operational Payment
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Operational Receive
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Bank Reconciliation
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-5">
      <i class="mdi mdi-cart-arrow-down h3 mr-1 align-middle" style="color: #8bc43e;"></i> Procurement Module
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Requisition
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Quotation
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Order
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Quotation Comparation (Base on PR)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Requisition Listing Report (Base on <span class="distance">
            Date, PR No, Cost Centre, etc)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multiple Discount up to 7 level discount
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Wizard through all transaction
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Division, Department, and Project
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Quotation Listing Report (Base on <span class="distance">
            Date, Supplier, PQ No, Cost Centre)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Customize print form (Enable user to custom <span
            class="distance">the preprinted form)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Part Puchase Quotation History List <span class="distance">
            (Comparation Part Price from Supplier)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Order Listing Report (Base on Date, <span class="distance">
            Supplier, PO No, Cost Centre, etc)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Purchase Order Report (Monitoring <span
            class="distance">
            PO Against Rec. DO)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Order Type Facility (To analys PO <span class="distance">
            base on type)</span>
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-server h3 mr-1 align-middle" style="color: #46978d;"></i> Services Module
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Service Requisition
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Service Order
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Job Completion
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Service Requisition Listing Report (Base on Date, <span
            class="distance"> SR No, Cost Centre,
            etc)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Service Order Listing Report (Base on Date, SR <span
            class="distance">
            No, Cost Centre, etc)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Service Order Report (Monitoring <span class="distance">
            WO Against Job Completion)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Work Order Type Facility (To analys PO base <span class="distance">
            on type)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multiple Discount up to 7 level discount
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Wizard through all transaction
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Division, Department, and Project
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-3" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-cube h2 mr-1 align-middle" style="color: #f5bc42;"></i>Inventory (Persediaan)
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Receive Delivery Order (related with Purchase <span
            class="distance">
            Order)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Purchase Return (related with Receive DO)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Delivery Order (related with Sales Order)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Return (related with Delivery Order)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Stock Issue / Stock Receipt
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Transfer Stock (From one warehouse to another)
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Stock Taking (to adjust stock for special <span class="distance">
            purpose)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Receive DO Report (Sorting by Date, Rec DO No, <span
            class="distance">
            Supplier, etc)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Receive DO Report (Monitoring Rec. <span
            class="distance">
            DO Against Rec.
            Invoice)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Stock Issue/Receipt Material Report by Project
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Stock Issue Compare with Budgetting Report <span class="distance">
            by
            Project</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Stock Card & Inventory Movement (with value) <span class="distance">
            Report</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Warehouse, Multi Unit of Measurement
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> FIFO, LIFO, and Average Costing Method
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Periodic or Perpetual Inventory Method
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16" [ngClass]="{'mt-5': isMobile}">
      <i class="mdi mdi-chart-line h3 mr-1 align-middle" style="color: #6557d6;"></i> Sales (Penjualan)
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Quotation
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Order
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Quotation & Sales Order Report (Base <span class="distance">
            on
            Date, Trans. No,
            Customer)</span>
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Outstanding Sales Order Repot (Monitoring <span class="distance"> SO
            Against DO)</span>
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Detail & Summary Analysis Report
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Wizard through all transaction
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Multi Division, Department, and Project
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Customize print form (Enable user to custom <span
            class="distance">the preprinted form)</span>
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-5">
      <i class="mdi mdi-autorenew h2 mr-1 align-middle" style="color: #ee5253"></i> Production Module
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Lot Number & Tracable
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Routing
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Work Centre Definition
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Machine
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Bill of Material
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Barcode & QR Code Support
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Job Order Tag
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Minimum & Maximum Stock
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Master Data Production
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Order
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Monitoring
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Assembly Process
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Budgeting
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Stock Monitoring
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Standart Costing
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-5">
      <i class="mdi mdi-wrench h3 mr-1 align-middle" style="color: #2ac081;"></i> Construction & Project Costing
      Module
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Master Project Setup
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Project Category
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Project Type
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Project Costing & Budgeting
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Material Issue Report by Project
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Material Issue / Recipt By Project
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Work in Progress (WIP) Project Repot
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Cost of Goods Sold by Project
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Income Statement Report by Project
        </p>
      </div>
    </div>

    <p class="font-weight-bold font-size-16 mt-5">
      <i class="mdi mdi-apple-keyboard-command h3 mr-1 align-middle" style="color: #02bbb2;"></i> Online Dashboard
    </p>
    <hr class="line-title">
    <div class="row">
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Procurement Dashboard
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Sales Dashboard
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Project Dashboard
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Production Dashboard
        </p>
      </div>
      <div class="col-xl-4">
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Finance & Accounting Dashboard
        </p>
        <p class="mb-2 font-size-14">
          <img src="assets/icons/check-circle.svg"> Document Monitoring
        </p>
      </div>
    </div>
  </div>
</section>