<div class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-5 mt-3">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'features5.sales' | translate}}
          </p>
          <hr style="margin-top: -10px;">
          <div *ngIf="isMobile">
            <img src="assets/images/feature-undraw/feature-5.png" class="w-100 mb-5">
          </div>
          <h2 class="text-dark font-weight-bold mb-3 font-mobile">
            <div innerHTML="{{'features5.salesHeader' | translate}}"></div>
          </h2>
          <p class="font-size-16">
            {{'features5.salesSub' | translate}}
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-1"></div> -->
      <div class="col-lg-6" *ngIf="!isMobile">
        <img src="assets/images/feature-undraw/feature-5.png" class="w-100" style="margin-top: 8em;">
      </div>
    </div>

  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);">
  <div class="container">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/sales-feature.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/monitoring-progress.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/report-analisa.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-view-dashboard"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{"features5.carousel1" | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{"features5.carousel1Sub" | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-find"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features5.carousel2' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{"features5.carousel2Sub" | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-multiple"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{"features5.carousel3" | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{"features5.carousel3Sub" | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote6></app-block-quote6>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/sq.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Sales Quotation <span class="text-primary">( SQ )</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features5.sq1Bold' |
                translate}}</b> <br>
              {{"features5.sq1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.sq2Bold" |
                translate}}</b> <br>
              {{"features5.sq2" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features5.sq3Bold' |
                translate}}</b> <br>
              {{"features5.sq3" | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/sq.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/so.jpg" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="line-height-1_4 mb-4">
          <b> Sales Order <span class="text-primary">( SO )</span> </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.so1Bold" |
                translate}}</b> <br>
              {{"features5.so1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.so2Bold" | translate}}</b> <br>
              {{"features5.so2" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b> {{"features5.so3Bold" |
                translate}}
              </b> <br>
              {{"features5.so3" | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/credit-limit.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div class="mb-4 mb-lg-0">
          <div innerHTML="{{'features5.detailHeader1' | translate}}"></div>
          <ul>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                <b>{{"features5.credLimit1Bold" | translate}}</b> <br>
                {{"features5.credLimit1" | translate}}
              </p>
            </li>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                <b>{{"features5.credLimit2Bold" | translate}}</b> <br>
                {{"features5.credLimit2" | translate}}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/credit-limit.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/customer-category.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features5.detailHeader2' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.custCat1Bold" | translate}}</b>
              <br>
              {{"features5.custCat1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.custCat2Bold" | translate}}</b> <br>
              {{"features5.custCat2" | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/zone-customer.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features5.detailHeader3' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.zona1Bold" |
                translate}}</b> <br>
              {{"features5.zona1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.zona2Bold" |
                translate}}</b> <br>
              {{"features5.zona2" | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/zone-customer.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/delivery-planning.jpg" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Delivery <span class="text-primary">Planning</span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.delivery1Bold" | translate}}</b>
              <br>
              {{"features5.delivery1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.delivery2Bold"| translate}}</b> <br>
              {{"features5.delivery2" | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/multi-adress.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features5.detailHeader4' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.address1Bold" | translate}}</b> <br>
              {{"features5.address1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{"features5.address2Bold" | translate}}</b> <br>
              {{"features5.address2" | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/multi-adress.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/name-product.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features5.detailHeader5' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.cust1Bold" |
                translate}}</b>
              <br>
              {{"features5.cust1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.cust2Bold" |
                translate}}</b> <br>
              {{"features5.cust2" | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/price-category.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features5.detailHeader6' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.price1Bold"
                | translate}}</b> <br>
              {{"features5.price1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.price2Bold"
                | translate}}</b> <br>
              {{"features5.price2" | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/price-category.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/top-n-sales.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            TOP N <span class="text-primary">Sales Report </span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.topN1Bold" |
                translate}}</b>
              <br>
              {{"features5.topN1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.topN2Bold" |
                translate}}</b> <br>
              {{"features5.topN2" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features5.topN3Bold" |
                translate}}</b> <br>
              {{"features5.topN3"| translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-sales-collapse></app-sales-collapse>