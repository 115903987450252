import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-now',
  templateUrl: './demo-now.component.html',
  styleUrls: ['./demo-now.component.scss']
})
export class DemoNowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goToDemo() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSejx_hM929V94nPGzYBmWS8CU7X8thQ5lK4hvvzJ0a2ta2r1w/viewform', '_blank')
  }

}
