import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  savedLang = localStorage.getItem('lang');
  currentSection = '';
  isMobile: boolean;

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.setLanguage();
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  hideMenu() {
    var btn = document.getElementById("navbar-t");
    btn.click();
  }

  private setLanguage() {
    this.translate.addLangs(['id', 'en']);
    this.translate.setDefaultLang(!this.savedLang ? 'id' : this.savedLang);
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  goToDemo() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSejx_hM929V94nPGzYBmWS8CU7X8thQ5lK4hvvzJ0a2ta2r1w/viewform', '_blank')
  }

  onChangeLang(lang: string) {
    this.savedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
