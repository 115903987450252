import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { Covid19Component } from './covid19/covid19.component';
import { ConstructionCompaniesComponent } from './solution/construction-companies/construction-companies.component';
import { DistributionCompaniesComponent } from './solution/distribution-companies/distribution-companies.component';
import { ManufactureCompaniesComponent } from './solution/manufacture-companies/manufacture-companies.component';
import { ServiceCompaniesComponent } from './solution/service-companies/service-companies.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from '../shared/features/features.component';
import { PricingComponent } from '../shared/pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { ClientsComponent } from './clients/clients.component';
import { AccountingProcessComponent } from './features/accounting-process/accounting-process.component';
import { ArApModuleComponent } from './features/ar-ap-module/ar-ap-module.component';
import { CashBankComponent } from './features/cash-bank/cash-bank.component';
import { CloudServicesComponent } from './features/cloud-services/cloud-services.component';
import { FixedAssetsComponent } from './features/fixed-assets/fixed-assets.component';
import { ForexGainLossComponent } from './features/forex-gain-loss/forex-gain-loss.component';
import { InventoryComponent } from './features/inventory/inventory.component';
import { ProcurementComponent } from './features/procurement/procurement.component';
import { RecurringJournalComponent } from './features/recurring-journal/recurring-journal.component';
import { SalesComponent } from './features/sales/sales.component';
import { SecurityComponent } from './features/security/security.component';
import { TransactionAnalysisReportComponent } from './features/transaction-analysis-report/transaction-analysis-report.component';
import { StrategyErpComponent } from './strategy-erp/strategy-erp.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'features',
    component: FeaturesComponent
  },
  {
    path: 'accounting-process',
    component: AccountingProcessComponent
  },
  {
    path: 'ar-ap-module',
    component: ArApModuleComponent
  },
  {
    path: 'cash-bank',
    component: CashBankComponent
  },
  {
    path: 'cloud-services',
    component: CloudServicesComponent
  },
  {
    path: 'fixed-assets',
    component: FixedAssetsComponent
  },
  {
    path: 'forex-gain-loss',
    component: ForexGainLossComponent
  },
  {
    path: 'inventory',
    component: InventoryComponent
  },
  {
    path: 'procurement',
    component: ProcurementComponent
  },
  {
    path: 'recurring-journal',
    component: RecurringJournalComponent
  },
  {
    path: 'sales',
    component: SalesComponent
  },
  {
    path: 'security',
    component: SecurityComponent
  },
  {
    path: 'transaction-analysis-report',
    component: TransactionAnalysisReportComponent
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'strategy-Implementasi',
    component: StrategyErpComponent
  },
  {
    path: 'clients',
    component: ClientsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'annual-maintenance',
    component: AnnualMaintenanceComponent
  },
  {
    path: 'construction-companies',
    component: ConstructionCompaniesComponent
  },
  {
    path: 'customize-module',
    component: CustomizeModuleComponent
  },
  {
    path: 'software-implementation',
    component: SoftwareImplementationComponent
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'distribution-companies',
    component: DistributionCompaniesComponent
  },
  {
    path: 'manufacture-companies',
    component: ManufactureCompaniesComponent
  },
  {
    path: 'service-companies',
    component: ServiceCompaniesComponent
  },
  {
    path: 'pernyataan-resmi-inforsys-terkait-covid-19',
    component: Covid19Component
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
