<div class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-5 mt-4">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'features3.checkBank' | translate}}
          </p>
          <hr style="margin-top: -10px;">
          <div class="col-lg-6" *ngIf="isMobile">
            <img src="assets/images/feature-undraw/feature-3.png" class="w-100">
          </div>
          <div innerHTML="{{'features3.checkBankHeader' | translate}}"></div>
          <p class="font-size-16">
            {{'features3.checkBankSub' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6" *ngIf="!isMobile">
        <img src="assets/images/feature-undraw/feature-3.png" class="w-100" style="margin-top: 5em;">
      </div>
    </div>

  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);">
  <div class="container">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/cash-bank-feature.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/monitoring-giro.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/recon-bank.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-map"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features3.carousel1' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features3.carousel1Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-receipt"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{"features3.carousel2" | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features3.carousel2Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-calendar-multiple"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features3.carousel3' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{"features3.carousel3Sub" | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote4></app-block-quote4>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/pencatatan-kas-pengeluaran.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features3.detailheader1' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features3.kas1Bold" |
                translate}}</b> <br>
              {{'features3.kas1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features3.kas2Bold' |
                translate}}</b> <br>
              {{'features3.kas2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/pencatatan-kas-pengeluaran.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/setup-menu.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features3.detailheader2' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features3.invoice1Bold' | translate}}</b> <br>
              {{'features3.invoice1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features3.invoice2Bold' | translate}}</b> <br>
              {{'features3.invoice2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/cash-flow-stt.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features3.detailheader3' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features3.cashFlow1Bold' | translate}}</b>
              <br>
              {{'features3.cashFlow1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features3.cashFlow2Bold' | translate}}</b> <br>
              {{'features3.cashFlow2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <img src="assets/images/ss-app/cash-flow-stt.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<app-cashbank-collapse></app-cashbank-collapse>