<div class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-5 mt-4">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> {{'features4.procurement' |
            translate}}
          </p>
          <hr style="margin-top: -10px;">
          <div *ngIf="isMobile">
            <img src="assets/images/feature-undraw/feature-4.png" class="w-100">
          </div>
          <div innerHTML="{{'features4.purchaseHeader' | translate}}"></div>
          <p class="font-size-16">
            {{'features4.purchaseSub' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6 mt-3 text-center" *ngIf="!isMobile">
        <img src="assets/images/feature-undraw/feature-4.png" width="80%">
      </div>
    </div>

  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/17.png);">
  <div class="container">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/procu-feature.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/monitoring-procu.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/tabulation-price.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-receipt"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features4.carousel1' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features4.carousel1Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-calendar-multiple"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features4.carousel2' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features4.carousel2Sub' | translate}}
            <br><br>
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-map"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">Tabulation Price</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features4.carousel3Sub' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote5></app-block-quote5>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/purchase-requisition.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Purchase Requisition <span class="text-primary">( PR )</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.pr1Bold' |
                translate}}</b> <br>
              {{'features4.pr1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.pr2Bold' |
                translate}}</b> <br>
              {{'features4.pr2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-2">
          <img src="assets/images/ss-app/purchase-requisition.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/rfq.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Request for Quotation <span class="text-primary">( RFQ ) </span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.rfq1Bold' |
                translate}}</b> <br>
              {{"features4.rfq1" | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{"features4.rfq2Bold" |
                translate}}</b> <br>
              {{'features4.rfq2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png);">
  <div class="container">
    <div class="row justify-content-center">
      <div class="w-100" *ngIf="isMobile">
        <img src="assets/images/ss-app/supp-compare.png" class="img-fluid d-block" alt="">
      </div>
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <hr style="width: 8em; border: 5px solid #1565c0;">
          <div innerHTML="{{'features4.compHeader' | translate}}"></div>
          <p class="font-size-15">
            {{"features4.compSub" | translate}}
          </p>
        </div>
      </div>
      <div class="w-100" *ngIf="!isMobile">
        <img src="assets/images/ss-app/supp-compare.png" class="img-fluid d-block" alt="">
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/purchase-order.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Purchase Order <span class="text-primary">( PO ) </span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.po1Bold' |
                translate}}</b> <br>
              {{'features4.po1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.po2Bold' |
                translate}}</b> <br>
              {{'features4.po2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/purchase-order.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -4em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mt-5 mb-lg-0">
          <br>
          <img src="assets/images/ss-app/map-produk.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features4.detailHeader4' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.supp1Bold' |
                translate}}</b> <br>
              {{'features4.supp1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.supp2Bold' |
                translate}}</b> <br>
              {{'features4.supp2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-block-quote1></app-block-quote1>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/supplier.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features4.detailHeader5' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.alias1Bold'
                | translate}}</b> <br>
              {{'features4.alias1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.alias2Bold'
                | translate}}</b> <br>
              {{'features4.alias2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/supplier.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
  <div class="container" style="margin-top: -4em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/supplier-2.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features4.detailHeader6' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.disc1Bold' |
                translate}}</b> <br>
              {{'features4.disc1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features4.disc2Bold' |
                translate}}</b> <br>
              {{'features4.disc2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-block-quote2></app-block-quote2>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/supplier.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features4.detailHeader7' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features4.konversi1Bold' | translate}}</b> <br>
              {{'features4.konversi1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features4.konversi2Bold' | translate}}</b> <br>
              {{'features4.konversi2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/supplier.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -4em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/purchase-report.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features4.detailHeader8' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features4.purchase1Bold' | translate}}</b> <br>
              {{'features4.purchase1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features4.purchase2Bold' | translate}}</b> <br>
              {{'features4.purchase2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-procurement-collapse></app-procurement-collapse>