<section>
  <div class=" container py-4">

    <div innerHTML="{{'blockquote.bq4Title' | translate}}"></div>
    <hr>

    <article class="postcard dark blue mt-4">
      <div class="postcard__img_link">
        <img class="postcard__img" src="assets/images/blockquote/4.jpg" alt="Image Title" />
      </div>
      <div class="postcard__text">
        <h1 class="postcard__title">Theodorus M. Tuanakotta, AK</h1>
        <div class="postcard__subtitle small">
          {{'blockquote.accounting' | translate}}
        </div>
        <div class="postcard__bar"></div>
        <div class="postcard__preview-txt">
          {{'blockquote.bq4' | translate}}
        </div>
      </div>
    </article>

    <hr>

  </div>
</section>