<section class="hero-1-bg" id="home">
  <div class="container">
    <!-- <div class="text-center mb-5">
      <h1 class="font-mobile">
        <div innerHTML="{{'about.start' | translate}}"></div>
      </h1>
      <hr style="border: 2px solid #1565c0; width: 20%;">
    </div> -->
    <br>
    <section class="mb-4">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-5 col-12">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/about-us.png" class="img-fluid w-100">
            </div>
          </div>
          <div class="col-lg-6">
            <div innerHTML="{{'about.content1' | translate}}"></div>
            <p class="font-size-16 mb-4">
              {{'about.content1Sub' | translate}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

<br><br><br>

<section class="mb-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/about-us2.png" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-lg-6">
        <div innerHTML="{{'about.content2' | translate}}"></div>
        <p class="font-size-16 mb-4">
          {{'about.content2Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-5 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/about-us2.png" class="img-fluid w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<br><br><br>

<section class="mb-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/about-us3.png" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div innerHTML="{{'about.content3' | translate}}"></div>
        <p class="font-size-16 mb-4">
          {{'about.content3Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<br><br><br>

<section class="mb-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/about-us4.png" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-lg-6">
        <div innerHTML="{{'about.content4' | translate}}"></div>
        <p class="font-size-16 mb-4">
          {{'about.content4Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-5 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/about-us4.png" class="img-fluid w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<br><br><br>

<section>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/about-us5.png" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div innerHTML="{{'about.content5' | translate}}"></div>
        <p class="font-size-16 mb-4">
          {{'about.content5Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<!-- <section>
 
</section> -->

<section class="hero-1-bg" style="background-image: url(assets/images/bg/home.png)">
  <div class="container">
    <div class="row">
      <div class="col-xl-6" *ngIf="isMobile">
        <div>
          <img src="assets/images/vission.jpg" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-xl-6" [ngClass]="{'mt-4': isMobile}">
        <div class="mb-5">
          <h2>
            <div innerHTML="{{'about.vision' | translate}}"></div>
          </h2>
          <!-- <hr style="border: 2px solid #1565c0; width: 140px;"> -->
          <p class="font-size-17">
            {{'about.visionSub' | translate}}
          </p>
        </div>
      </div>
      <div class="col-xl-6" *ngIf="!isMobile">
        <div>
          <img src="assets/images/vission.jpg" class="img-fluid w-100">
        </div>
      </div>
    </div>
  </div>
  <br><br>
  <div class="container">
    <div class="row">
      <div class="col-xl-6" *ngIf="isMobile">
        <div style="margin-top: 2em;">
          <img src="assets/images/mission.jpg" class="img-fluid w-100">
        </div>
      </div>
      <div class="col-xl-6" [ngClass]="{'mt-4': isMobile}">
        <div class="mb-5">
          <h2>
            <div innerHTML="{{'about.mission' | translate}}"></div>
          </h2>
          <!-- <hr style="border: 2px solid #1565c0; width: 140px;"> -->
          <div style="margin-left: -20px;">
            <div innerHTML="{{'about.missionSub' | translate}}"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-6" *ngIf="!isMobile">
        <div style="margin-top: 2em;">
          <img src="assets/images/mission.jpg" class="img-fluid w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row justify-content-center mb-4">
      <div class="col-xl-8 text-center mt-4 mb-5">
        <div class="mb-5">
          <div innerHTML="{{'about.value' | translate}}"></div>
          <hr style="border: 2px solid #1565c0; width: 140px; margin-bottom: 10px;">
        </div>
        <div class="row">
          <div class="col-xl-4 mb-3">
            <p class="mb-4" style="margin-left: -40px;">
              <img src="assets/icons/integrity.png" width="15%" style="margin-right: 10px  ; margin-bottom: 5px;">
              <b>INTEGRITY</b>
            </p>
          </div>
          <div class="col-xl-4 mb-3">
            <p class="mb-4">
              <img src="assets/icons/innovation.png" width="20%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>INNOVATION</b>
            </p>
          </div>
          <div class="col-xl-4">
            <p>
              <img src="assets/icons/teamwork.png" width="20%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>TEAMWORK</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 text-right" [ngClass]="{'communication': isMobile}">
            <p>
              <img src="assets/icons/communication.jpg" width="12%" style="margin-right: 10px; margin-bottom: 5px;">
              <span style="margin-right: 10px;"><b>COMMUNICATION</b></span>
            </p>
          </div>
          <div class="col-xl-6" [ngClass]="{'simplicity': isMobile}">
            <p>
              <img src="assets/icons/simplicity.png" width="15%">
              <b>SIMPLICITY</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our-team-section" style="background-image: url(assets/images/bg/15.png); margin-top: -3.5em;">
  <div class="container">
    <div class="row justify-content-center">
      <div class=" col-lg-6 text-center mb-5">
        <h2 class="mb-3">
          Meet <span class="text-primary">Our Team</span>
        </h2>
        <p class="font-size-16">
          {{'about.team' | translate}}
        </p>
        <hr class="mt-4" style="width: 100px; border: 3px solid #1565c0;">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/ceo.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Yusvizal Fepwarmen</h3>
            <span class="post">CEO PT Inforsys</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/yvizal" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/cto.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Fatur</h3>
            <span class="post">CTO PT Inforsys</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/coo.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Riza Hendri Yopie</h3>
            <span class="post">COO PT Inforsys</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/yopie_rh" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/avatar.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Ahmad Saifullah</h3>
            <span class="post">Manager Implementation</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/avatar.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Imam Asyari</h3>
            <span class="post">Manager Product</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/benny.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Benny Irada</h3>
            <span class="post">Software & Database Engineer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/fredi.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Fredi Yansahh</h3>
            <span class="post">Software & Database Engineer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/oza.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Oza Krisna Erison</h3>
            <span class="post">SOFICloud Consultant</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/ozakrisnaerison/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/jo.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Johan</h3>
            <span class="post">Software & Database Engineer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/elsa.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Elsa Lestary</h3>
            <span class="post">SOFICloud Consultant</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/rrhmdini/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/avatar.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Fery Holanda</h3>
            <span class="post">Full-Stack Developer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/feryholanda_" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/yudha.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">M. Yudha Putra</h3>
            <span class="post">Full-Stack Developer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/yudha1404_/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/erwin.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Erwin Tan</h3>
            <span class="post">Full-Stack Developer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/_erwin.tan" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>


    </div>

    <div class="row mt-4">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/ahmed.jpg">
          </div>
          <div class="team-content">
            <h3 class="title">Dian Ahmed Ar Ridho</h3>
            <span class="post">Front-End Developer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/dianahmed21/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="our-team">
          <div class="pic">
            <img src="assets/images/team/dini.jfif">
          </div>
          <div class="team-content">
            <h3 class="title">Rahma Dini</h3>
            <span class="post">Front-End Developer</span>
          </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" class="mdi mdi-facebook"></a>
            </li>
            <li>
              <a href="https://www.instagram.com/rrhmdini/" target="_blank" class="mdi mdi-instagram"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-email"></a>
            </li>
            <li>
              <a href="#" target="_blank" class="mdi mdi-linkedin"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>


  </div>
</section>