<div class="hero-1-bg" style="background-image: url('assets/images/bg/homie.png');">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-10 text-center">
        <h1 class="text-dark font-weight-bold mb-5 font-mobile">
          <div innerHTML="{{'home.start' | translate}}"></div>
        </h1>
        <button type="button" class="btn btn-primary btn-sm mr-2" (click)="goToWA()">
          <span class="mdi mdi-whatsapp h5 mr-1"></span>
          {{'header.contact' | translate}}
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#myModal">
          <span class="mdi mdi-play h5 mr-1"></span>
          {{'home.video' | translate}}
        </button>
      </div>
    </div>

    <div class="img-home mt-5">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/home.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/home-1.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/home-2.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/home-3.png" class="d-block img-fluid" alt="slide 4">
        </ng-template>
      </ngb-carousel>
    </div>


    <div class="row" style="margin-top: 5em;">
      <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-collage"></i>
          </div>
          <div innerHTML="{{'home.caraousel1' | translate}}"></div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-book-open"></i>
          </div>
          <div innerHTML="{{'home.caraousel2' | translate}}"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-trending-up"></i>
          </div>
          <div innerHTML="{{'home.caraousel3' | translate}}"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-3'}" (click)="goToCarousel('ngb-slide-3')">
          <div class="wc-box-icon">
            <i class="mdi mdi-format-list-checkbox"></i>
          </div>
          <div innerHTML="{{'home.caraousel4' | translate}}"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section feather-bg-img">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <div innerHTML="{{'home.featureHeader' | translate}}"></div>
          <hr style="width: 20%; border: 2px solid #1565c0;">
          <p class="font-size-16">
            {{'home.featureHeaderSub' | translate}}
          </p>
        </div>
      </div>
    </div>

    <div class="mb-5 img-feature">
      <img src="assets/images/ss-app/home-sofi.png" class="d-block img-fluid">
    </div>

    <div class="row">
      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-clipboard icon-feature-1"></i>
        <div innerHTML="{{'home.feature1' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-book-multiple icon-feature-2"></i>
        <div innerHTML="{{'home.feature2' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-bank icon-feature-3"></i>
        <div innerHTML="{{'home.feature3' | translate}}"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-factory icon-feature-4"></i>
        <div innerHTML="{{'home.feature4' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-currency-usd icon-feature-5"></i>
        <div innerHTML="{{'home.feature5' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-package-variant-closed icon-feature-6"></i>
        <div innerHTML="{{'home.feature6' | translate}}"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-chart-pie icon-feature-7"></i>
        <div innerHTML="{{'home.feature7' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-city icon-feature-8"></i>
        <div innerHTML="{{'home.feature8' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-autorenew icon-feature-9"></i>
        <div innerHTML="{{'home.feature9' | translate}}"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-finance icon-feature-10"></i>
        <div innerHTML="{{'home.feature10' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-security icon-feature-11"></i>
        <div innerHTML="{{'home.feature11' | translate}}"></div>
      </div>

      <div class="col-xl-4 mt-5" data-aos="zoom-in-up" data-aos-duration="1500">
        <i class="mdi mdi-cloud-check icon-feature-12"></i>
        <div innerHTML="{{'home.feature12' | translate}}"></div>
      </div>
    </div>
  </div>
</section>

<app-block-quote1></app-block-quote1>

<section class="section" style="background-image: url(assets/images/bg/19.png)">
  <div class="container mt-5 feather-bg-img">
    <div class="row">
      <div class="col-lg-7">
        <div class="mt-5 mb-5">
          <img src="assets/images/ss-app/keunggulan.gif" alt="" class="img-fluid d-block border-dark">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <div innerHTML="{{'home.superior' | translate}}"></div>
        <ul>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior1' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior2' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior3' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior4' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior5' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior6' | translate}}
            </p>
          </li>
          <li>
            <p>
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              {{'home.superior7' | translate}}
            </p>
          </li>
        </ul>
        <div class="mt-5">
          <button (click)="goToWA()" class="btn btn-primary mr-3 btn-sm" style="box-shadow: none;">
            {{'header.contact' | translate}}
          </button>
          <a routerLink="/features" class="text-primary font-weight-semibold">{{'home.read' | translate}}<span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>

      </div>
    </div>
  </div>
</section>

<br><br><br><br>

<!-- <app-testimonial></app-testimonial> -->

<br><br><br><br>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/20.png)">
  <div class="container feather-bg-img" style="margin-top: -6em; margin-bottom: -6em;">
    <div class="row">
      <div class="col-lg-7">
        <div class=" mt-5 mt-lg-0 mb-5">
          <img src="assets/images/ss-app/solution.gif" class="img-fluid d-block mx-auto border-dark">
        </div>
      </div>
      <div class="col-lg-4 offset-lg-1">
        <div innerHTML="{{'home.solution' | translate}}"></div>
        <div class="row">
          <div class="col-xl-5">
            <ul>
              <li>
                <p>
                  <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                  {{'header.solution1' | translate}}
                </p>
              </li>
              <li>
                <p>
                  <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                  {{'header.solution2' | translate}}
                </p>
              </li>
              <li>
                <p>
                  <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                  {{'header.solution3' | translate}}
                </p>
              </li>
              <li>
                <p>
                  <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                  {{'header.solution4' | translate}}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-4">
          <a (click)="goToWA()" class="btn btn-primary mr-3 btn-sm text-white" style="box-shadow: none;">
            {{'header.contact' | translate}}
          </a>
          <a routerLink="/about" class="text-primary font-weight-semibold">{{'home.read' | translate}} <span
              class="ml-2 right-icon">&#8594;</span></a>
        </div>
      </div>
    </div>
  </div>
</section>

<br><br>

<app-erp-benefits></app-erp-benefits>

<!-- <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item"
            src="https://www.youtube.com/embed/Jfrjeg26Cwk?autoplay=1&amp;modestbranding=1&amp;showinfo=0" id="video"
            allowscriptaccess="always"></iframe>
        </div>
      </div>
    </div>
  </div>
</div> -->