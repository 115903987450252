<div class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4" [ngClass]="{'mb-5': isMobile}">
        <img src="assets/images/inforsys-white.png" alt="" class="mb-4" height="35" width="53%">
        <div class="row text-white mb-3">
          <i class="mdi mdi-map-marker h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">PT Inforsys Indonesia <br> Orchard Park Blok C No. 18 Agung Podomoro Land
              Batam,
              Kepulauan Riau 29464 Indonesia</span>
          </div>
        </div>

        <div class="row text-white mb-3">
          <i class="mdi mdi-phone h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">(+62) 778 416 0250</span>
          </div>
        </div>

        <div class="row text-white">
          <i class="mdi mdi-email-outline h5" style="margin-left: 12px;"> </i>
          <div class="col-lg-10">
            <span style="font-size: 15px;">hello@inforsys.co.id</span>
          </div>
        </div>
      </div>

      <div class="col-md-3" [ngClass]="{'mb-5': isMobile}">
        <h6 class="text-white text-uppercase mb-3"><b>About Us</b></h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="about" class="footer-link li a">About Us</a></li>
          <li><a href="https://inforsys.co.id/about" target="_blank" class="footer-link li a">About Inforsys</a>
          </li>
          <li><a href="terms-conditions" class="footer-link li a">Terms & Conditions</a></li>
          <li><a href="privacy" class="footer-link li a">Privacy Policy</a></li>
          <li><a href="pernyataan-resmi-inforsys-terkait-covid-19" class="footer-link li a">Covid-19</a></li>
        </ul>
      </div>

      <div class="col-md-2" [ngClass]="{'mb-5': isMobile}">
        <h6 class="text-white text-uppercase mb-3"><b>Customers</b></h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="manufacture-companies" class="footer-link li a">Manufacture</a></li>
          <li><a href="distribution-companies" class="footer-link li a">Distributor</a></li>
          <li><a href="construction-companies" class="footer-link li a">Construction</a></li>
          <li><a href="service-companies" class="footer-link li a">Services</a></li>
        </ul>
      </div>
      <div class="col-md-3 ">
        <h6 class="text-white text-uppercase mb-3"><b>Support</b></h6>
        <ul class="list-unstyled footer-sub-menu">
          <li><a href="strategy-Implementasi" class="footer-link li a">Software Implementation</a></li>
          <li><a href="training" class="footer-link li a">Training & Re-Implementation</a></li>
          <li><a href="annual-maintenance" class="footer-link li a">Annual Software Maintenance</a></li>
          <li><a href="customize-module" class="footer-link li a">Customize Module</a></li>
        </ul>
        <br>
        <div>
          <a href="https://pse.kominfo.go.id/home" target="_blank" >
            <img src="assets/icons/pse-terdaftar.png" class="icon-pse">
          </a>&nbsp;
          <img src="assets/icons/comodo.png" class="icon-comodo">
          <!-- <ul class="wrapper list-inline footer-social-icon-content">
            <li class="icon facebook list-inline-item">
              <span class="tooltip">Facebook</span>
              <a href="https://id-id.facebook.com/inforsys.co.id/" target="_blank" class="footer-social-icon">
                <img src="assets/icons/facebook.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon twitter list-inline-item">
              <span class="tooltip">Twitter</span>
              <a href="https://twitter.com/inforsys_batam" target="_blank" class="footer-social-icon">
                <img src="assets/icons/twitter.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon instagram list-inline-item">
              <span class="tooltip">Instagram</span>
              <a href="https://www.instagram.com/inforsys.co.id/" target="_blank" class="footer-social-icon">
                <img src="assets/icons/instagram.png" class="icon-sosmed">
              </a>
            </li>
            <li class="icon linkedin list-inline-item">
              <span class="tooltip">Linkedin</span>
              <a href="https://www.linkedin.com/company/pt-inforsys-indonesia-tbk-/mycompany/" target="_blank"
                class="footer-social-icon">
                <img src="assets/icons/linkedin.png" class="icon-sosmed">
              </a>
            </li>
          </ul> -->
        </div>
      </div>

      <!-- <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-4">About Us</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="about" class="footer-link">About Us</a></li>
              <li><a href="https://inforsys.co.id/about" class="footer-link" target="_blank">About Inforsys</a></li>
              <li><a href="terms-conditions" class="footer-link">Terms & Conditions</a></li>
              <li><a href="privacy" class="footer-link">Privacy Policy</a></li>
              <li><a href="pernyataan-resmi-inforsys-terkait-covid-19" class="footer-link">Covid-19</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Customers</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="manufacture-companies" class="footer-link">Manufacture</a></li>
              <li><a href="distribution-companies" class="footer-link">Distributor</a></li>
              <li><a href="construction-companies" class="footer-link">Construction</a></li>
              <li><a href="service-companies" class="footer-link">Services</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Support</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="strategy-Implementasi" class="footer-link">Software Implementation</a></li>
              <li><a href="training" class="footer-link">Training & Re-Implementation</a></li>
              <li><a href="annual-maintenance" class="footer-link">Annual Software Maintenance</a></li>
              <li><a href="customize-module" class="footer-link">Customize Module</a></li>
            </ul>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-70 font-size-15 mb-0">Copyright © 1998 - {{year}} PT Inforsys Indonesia</p>
        </div>
      </div>
    </div>
  </div>
</div>