import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

import { PagesRoutingModule } from './pages-routing.module';

import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { ServiceCompaniesComponent } from './solution/service-companies/service-companies.component';
import { ManufactureCompaniesComponent } from './solution/manufacture-companies/manufacture-companies.component';
import { DistributionCompaniesComponent } from './solution/distribution-companies/distribution-companies.component';
import { ConstructionCompaniesComponent } from './solution/construction-companies/construction-companies.component';
import { Covid19Component } from './covid19/covid19.component';
import { HomeComponent } from './home/home.component';
import { AccountingProcessComponent } from './features/accounting-process/accounting-process.component';
import { ArApModuleComponent } from './features/ar-ap-module/ar-ap-module.component';
import { CashBankComponent } from './features/cash-bank/cash-bank.component';
import { CloudServicesComponent } from './features/cloud-services/cloud-services.component';
import { FixedAssetsComponent } from './features/fixed-assets/fixed-assets.component';
import { ForexGainLossComponent } from './features/forex-gain-loss/forex-gain-loss.component';
import { InventoryComponent } from './features/inventory/inventory.component';
import { ProcurementComponent } from './features/procurement/procurement.component';
import { RecurringJournalComponent } from './features/recurring-journal/recurring-journal.component';
import { SalesComponent } from './features/sales/sales.component';
import { SecurityComponent } from './features/security/security.component';
import { TransactionAnalysisReportComponent } from './features/transaction-analysis-report/transaction-analysis-report.component';
import { ClientsComponent } from './clients/clients.component';
import { StrategyErpComponent } from './strategy-erp/strategy-erp.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    IndexComponent,
    HomeComponent,

    //features
    AccountingProcessComponent,
    ArApModuleComponent,
    CashBankComponent,
    CloudServicesComponent,
    FixedAssetsComponent,
    ForexGainLossComponent,
    InventoryComponent,
    ProcurementComponent,
    RecurringJournalComponent,
    SalesComponent,
    SecurityComponent,
    TransactionAnalysisReportComponent,

    PrivacyComponent,
    TermsConditionsComponent,
    SoftwareImplementationComponent,
    TrainingComponent,
    AnnualMaintenanceComponent,
    CustomizeModuleComponent,
    AboutComponent,
    ServiceCompaniesComponent,
    ManufactureCompaniesComponent,
    DistributionCompaniesComponent,
    ConstructionCompaniesComponent,
    Covid19Component,
    ClientsComponent,
    StrategyErpComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    RouterModule,
    SharedModule,
    ScrollToModule.forRoot(),
    NgbModalModule,
    NgbModule,
    NgxYoutubePlayerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class PagesModule { }
