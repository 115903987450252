<section class="section" style="background-image: url(assets/images/bg/17.png);" id="contact">
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class=" col-lg-6 text-center mb-5">
        <h1 class="mb-3">
          <div innerHTML="{{'contact.title' | translate}}"></div>
        </h1>
        <p class="font-size-16">
          {{'contact.subtitle' | translate}}
        </p>
        <hr class="mt-4" style="width: 100px; border: 3px solid #1565c0;">
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4">
        <h3><b>PT Inforsys Indonesia</b></h3>
        <hr>
        <div class="contact-detail">
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="mail"></i-feather>
            <span>hello@inforsys.co.id</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="link"></i-feather> <span>inforsys.co.id</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="phone-call"></i-feather> <span>+62 778 416
              0250</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="message-circle"></i-feather> <span>+62 811 7774
              744</span>
          </p>

          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="clock"></i-feather> <span>8:00 AM - 5:00 PM</span>
          </p>
          <p class="">
            <i-feather class="icon-xs icon mr-2 text-primary" name="map-pin"></i-feather>
            <span>Orchard Park Blok C No.18</span> <br>
            <span class="address-text">Batam, Kepulauan Riau 29464, Indonesia</span>
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15956.250868436284!2d104.0652137!3d1.1151026!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3d0025037edd0b60!2sInforsys%20Indonesia!5e0!3m2!1sid!2sid!4v1655455525549!5m2!1sid!2sid"
          width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</section>