<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <p class="font-weight-medium text-uppercase mb-2"><i
            class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> Creative Features</p>
        <h3 class="font-weight-semibold line-height-1_4 mb-4">We do the work you <b>stay focused</b> on <b>your
            customers</b>.</h3>
        <!-- <h3 class="font-weight-semibold line-height-1_4 mb-4">Build <b>community</b> & <b>conversion</b> with our suite of <b>social tool</b></h3> -->
        <p class="text-muted font-size-15 mb-4"></p>

      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="mt-4 mt-lg-0">
          <img src="assets/images/undraw_goals_w8tw.svg" alt="" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>
    <br>
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Segment</h3>
          <p class="text-muted font-size-15">SOFI sangat cocok digunakan untuk perusahaan</p>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5" >
              <div class=" mt-5 mt-lg-0">
                <img src="assets/images/undraw_factory_dy0a.svg" alt="" class="img-fluid d-block mx-auto">
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Manufacture</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle"><a href="manufacture-companies" class="btn btn-primary mr-2">Read More</a> </p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class=" mt-5 mt-lg-0">
                <img src="assets/images/undraw_logistics_x4dc.svg" alt="" class="img-fluid d-block mx-auto">
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Distributor</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle"> <a href="distribution-companies" class="btn btn-primary mr-2">Read More</a></p>
            </div>

          </div>
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class=" mt-5 mt-lg-0">
                <img src="assets/images/undraw_Golden_gate_bridge_jkph.svg" alt="" class="img-fluid d-block mx-auto">
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Construction</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle"><a href="construction-companies" class="btn btn-primary mr-2">Read More</a></p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class=" mt-5 mt-lg-0">
                <img src="assets/images/undraw_aircraft_fbvl.svg" alt="" class="img-fluid d-block mx-auto">
              </div>
              <h5 class="font-weight-bold mb-2 wc-title mt-4">Services</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle"><a href="construction-companies" class="btn btn-primary mr-2">Read More</a></p>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</section>
