<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/5.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold mb-4 text-center">Privacy <span
          class="text-primary">Policy</span></h1>
      <hr style="width: 20%; border: 2px solid #1565c0;">
      <br><br><br>
      <div innerHTML="{{'privacy' | translate}}"></div>
    </div>
  </section>
  <!-- Hero End -->

</div>