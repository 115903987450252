<app-header></app-header>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/7.1.png)">
  <div class="container">
    <div class="row">
      <div class="container">
        <div class="text-center">

          <h1 class="mb-3"><b>Annual <span class="text-primary"> Software </span>Maintenance</b></h1>
          <p class="font-size-16">
            {{'annualSoftware.annualSub' | translate}}
          </p>

          <hr style="border: 2px solid #1565c0; width: 13em;">

          <img src="assets/images/software-Maintenance.jpg" alt="" class="img-fluid d-block mx-auto mt-5" width="50%">
        </div>

        <div class="mt-5">
          <p class="font-size-16 mb-4">
            {{'annualSoftware.annual1' | translate}}
          </p>
          <ul class="font-size-15 mb-4">
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content1Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content2Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content3Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content4Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content5Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content6Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content7Sub' | translate}}
            </li>
          </ul>
          <p class="font-size-15 mb-4">
            {{'annualSoftware.annual2' | translate}}
          </p>
          <ul class="font-size-15 mb-4">
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content8Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content9Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content10Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content11Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content12Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content13Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content14Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content15Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content16Sub' | translate}}
            </li>
            <li>
              <i-feather class="icon-xs mr-1 text-primary" name="send"></i-feather>
              {{'annualSoftware.content17Sub' | translate}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>