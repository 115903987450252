<div class="section feather-bg-img" style="background-image: url(assets/images/bg/6.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-5 mt-3">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i>{{"features6.inventory" | translate}}
            <br> <span class="ml-4">( Inventory Module )</span>
          </p>
          <hr style="margin-top: -10px;">
          <div *ngIf="isMobile">
            <img src="assets/images/feature-undraw/feature-6.png" width="90%">
          </div>
          <div innerHTML="{{'features6.inventoryHeader' | translate}}"></div>
          <p class="font-size-16">
            {{'features6.inventorySub' | translate}}
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-1"></div> -->
      <div class="col-lg-6 text-center" style="margin-top: 6em;" *ngIf="!isMobile">
        <img src="assets/images/feature-undraw/feature-6.png" width="90%">
      </div>
    </div>
  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/5.png);">
  <div class="container" style="margin-top: -2em;">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/sistem-persediaan-1.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/sistem-persediaan-2.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/note-metode.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-view-dashboard"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features6.carousel1' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features6.carousel1Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-find"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features6.carousel2' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features6.carousel2Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-multiple"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features6.carousel3' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features6.carousel3Sub' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote7></app-block-quote7>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/grn.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features6.detailHeader1' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.grn1Bold' |
                translate}}</b> <br>
              {{'features6.grn1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.grn2Bold' | translate}}</b> <br>
              {{'features6.grn2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/grn.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/dashboard-po.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features6.detailHeader2' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.po1Bold' |
                translate}}</b> <br>
              {{'features6.po1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Outstanding PO Against
                GRN</b> <br>
              {{'features6.po2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/purchase-return.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div class="mb-4 mb-lg-0">
          <div innerHTML="{{'features6.detailHeader3' | translate}}"></div>
          <ul>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.pr1Bold' |
                  translate}}</b> <br>
                {{'features6.pr1' | translate}}
              </p>
            </li>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.pr2Bold' |
                  translate}}</b> <br>
                {{'features6.pr2' | translate}}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/purchase-return.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/do.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features6.detailHeader4' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b> {{'features6.do1Bold'
                |translate}}</b><br>
              {{'features6.do1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.do2Bold' |
                translate}}</b> <br>
              {{'features6.do2' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.do3Bold' |
                translate}}</b> <br>
              {{'features6.do3' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/monitoring-so.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <div innerHTML="{{'features6.detailHeader5' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.so1Bold' | translate}}</b> <br>
              {{'features6.so1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Outstanding SO Against
                DO</b> <br>
              {{'features6.so2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/monitoring-so.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/sales-return.jpg" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features6.detailHeader6' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.sr1Bold' |
                translate}}</b>
              <br>
              {{'features6.sr1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.sr2Bold' |
                translate}}</b> <br>
              {{'features6.sr2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/warehouse.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Multi <span class="text-primary">Location & Warehouse</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.wrhouse1Bold' | translate}}</b> <br>
              {{'features6.wrhouse1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.wrhouse2Bold'
                | translate}}</b> <br>
              {{'features6.wrhouse2' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>Re-Order Point</b> <br>
              {{'features6.wrhouse3' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/warehouse.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/multi-rack.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Multi Rack, Level, <br> <span class="text-primary">Column, & Shelf</span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.rack1Bold' |
                translate}}</b><br>
              {{'features6.rack1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.rack2Bold' |
                translate}}</b> <br>
              {{'features6.rack2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/uom.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Multi Unit of <br> <span class="text-primary">Measurement (UoM)</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.uom1Bold' |
                translate}}</b> <br>
              {{'features6.uom1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.uom2Bold' |
                translate}}</b> <br>
              {{'features6.uom2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/uom.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/stock-opname.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div innerHTML="{{'features6.detailHeader7' | translate}}"></div>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.opname1Bold'
                | translate}}</b><br>
              {{'features6.opname1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.opname2Bold'
                | translate}}</b> <br>
              {{'features6.opname2' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.opname3Bold'
                | translate}}</b> <br>
              {{'features6.opname3' | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -1em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/barcode.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Barcode / QRCode <span class="text-primary">Support</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.barcode1Bold' | translate}}</b>
              <br>
              {{'features6.barcode1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.barcode2Bold' | translate}}</b> <br>
              {{'features6.barcode2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/barcode.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top:-2em">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/serial-number.jpg" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Product <span class="text-primary">Serial Number</span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.serNumber1Bold' | translate}}</b><br>
              {{'features6.serNumber1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.serNumber2Bold' | translate}}</b> <br>
              {{'features6.serNumber2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -1em;">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/prod-spec.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Product Dimension <span class="text-primary">& Specification </span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.spec1Bold' |
                translate}}</b><br>
              {{'features6.spec1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.spec2Bold' | translate}}</b>
              <br>
              {{'features6.spec2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/prod-spec.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/lot-number.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Lot <span class="text-primary">Number Tracking</span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.lot1Bold' |
                translate}}</b><br>
              {{'features6.lot1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features6.lot2Bold' |
                translate}}</b> <br>
              {{'features6.lot2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/stock-card-report.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Stock Card <span class="text-primary">Report</span>
          </b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.stockCard1Bold' | translate}}</b><br>
              {{'features6.stockCard1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.stockCard2Bold' | translate}}</b> <br>
              {{'features6.stockCard2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/stock-card-report.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container" style="margin-top: -2em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-5">
          <img src="assets/images/ss-app/inv-mov-report.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>
            Inventory Movement <span class="text-primary">Report</span>
          </b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.movement1Bold' | translate}}</b><br>
              {{'features6.movement1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features6.movement2Bold' | translate}}</b> <br>
              {{'features6.movement2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-inventory-collapse></app-inventory-collapse>