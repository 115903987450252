<section class="hero-1-bg" style="background-image: url(assets/images/bg/17.png)" id="home">
  <div class="container">
    <div class="text-center">
      <div innerHTML="{{'solution.construction.start' | translate}}"></div>
      <p class="font-size-16">
        {{'solution.construction.startSub' | translate}}
      </p>
      <div class="img-menu">
        <img src="assets/images/konstruksi.jpg" alt="" class="img-fluid d-block">
      </div>
    </div>
  </div>
</section>

<div style="margin-top: -2em;">
  <app-block-quote1></app-block-quote1>
</div>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content1' | translate}}"></div>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'solution.construction.content1Sub1Bold' | translate}}</b><br>
              {{'solution.construction.content1Sub1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>Project Contract Value</b><br>
              {{'solution.construction.content1Sub2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/solution/construction2.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content2' | translate}}"></div>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'solution.construction.content2Sub1Bold' | translate}}</b><br>
              {{'solution.construction.content2Sub1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'solution.construction.content2Sub2Bold' | translate}}</b><br>
              {{'solution.construction.content2Sub2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction3.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-4">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content3' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.construction.content3Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-7 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction3.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="section hero-1-bg" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center" style="margin-top: -7em;">
      <div class="col-lg-6">
        <div class="mt-3">
          <img src="assets/images/solution/construction4.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content4' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.construction.content4Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<app-block-quote2></app-block-quote2>

<section class="section hero-1-bg" style="background-color: #f8f9fa">
  <div class="container" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction5.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content5' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.construction.content5Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction5.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mt-3">
          <img src="assets/images/solution/construction6.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content6' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.construction.content6Sub' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<app-inventory-collapse></app-inventory-collapse>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-1" *ngIf="isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction7.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <div innerHTML="{{'solution.construction.content7' | translate}}"></div>
        </h3>
        <p class="mb-2 font-size-15">
          {{'solution.construction.content7Sub' | translate}}
        </p>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/solution/construction7.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero-1-bg" style="background-image: url(assets/images/bg/12.png)">
  <div class="text-center">
    <h2> <b>Project Report <span class="text-primary">Analysis</span></b> </h2>
  </div>

  <div class="container img-menu mt-5">
    <img src="assets/images/solution/construction8.png" class="img-fluid d-block">
  </div>
</section>


<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center">
    <h2>Actual Cost vs <span class="text-primary">Budget Monitoring</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/construction9.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/12.png)">
  <div class="text-center">
    <h2 class="font-weight-bold">Project Cost Detail <span class="text-primary">Analysis Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/construction10.png" class="img-fluid d-block" width="90%">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center">
    <h2 class="font-weight-bold">Project Contract & <span class="text-primary">Budgeting Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/construction11.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/12.png)">
  <div class="text-center">
    <h2 class="font-weight-bold">Stock Issue by <span class="text-primary">Project - Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/construction12.png" class="img-fluid d-block">
  </div>
</section>

<section class="section hero-1-bg" style="background-image: url(assets/images/bg/9.png)">
  <div class="text-center">
    <h2 class="font-weight-bold">Project Costing & <span class="text-primary">Billing Report</span></h2>
  </div>

  <div class="container mt-5">
    <img src="assets/images/solution/construction13.png" class="img-fluid d-block">
  </div>
</section>

<app-accounting-process-collapse></app-accounting-process-collapse>