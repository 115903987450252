<div class="section feather-bg-img" style="background-image: url(assets/images/bg/6.png);" id="features">
  <div class="container mt-5">
    <div class="row">
      <div class="col-lg-5">
        <div class="mb-5 mt-4">
          <p class="font-weight-bold">
            <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i>
            {{'features7.arAp' | translate}}
          </p>
          <hr style="margin-top: -10px;">
          <div *ngIf="isMobile">
            <img src="assets/images/feature-undraw/feature-7.png" class="w-100 mb-5">
          </div>
          <div innerHTML="{{'features7.arApHeader' | translate}}"></div>
          <p class="font-size-16">
            {{'features7.arApSub' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-6 mt-4 text-center" *ngIf="!isMobile">
        <img src="assets/images/feature-undraw/feature-7.png" width="80%">
      </div>
    </div>

  </div>
</div>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/5.png);">
  <div class="container">
    <div class="img-home">
      <ngb-carousel #carouselStart [showNavigationArrows]="false" [showNavigationIndicators]="false"
        (slide)="getIndexCarousel($event)">
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/carousel-ar-ap-1.png" class="d-block img-fluid" alt="slide 1">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/carousel-ar-ap-2.png" class="d-block img-fluid" alt="slide 2">
        </ng-template>
        <ng-template ngbSlide>
          <img src="assets/images/ss-app/carousel-ar-ap-3.png" class="d-block img-fluid" alt="slide 3">
        </ng-template>
      </ngb-carousel>
    </div>

    <div class="row" style="margin-top: 5em;">
      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-0'}" (click)="goToCarousel('ngb-slide-0')">
          <div class="wc-box-icon">
            <i class="mdi mdi-text-box-search"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features7.carousel1' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features7.carousel1Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-1'}" (click)="goToCarousel('ngb-slide-1')">
          <div class="wc-box-icon">
            <i class="mdi mdi-calendar-search"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features7.carousel2' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features7.carousel2Sub' | translate}}
          </p>
        </div>
      </div>

      <div class="col-md-4">
        <div class="wc-box rounded text-center wc-box-primary p-4 carousel-card"
          [ngClass]="{'carousel-active': indexCarousel === 'ngb-slide-2'}" (click)="goToCarousel('ngb-slide-2')">
          <div class="wc-box-icon">
            <i class="mdi mdi-file-chart"></i>
          </div>
          <p class="font-weight-bold mb-2 wc-title mt-4">{{'features7.carousel3' | translate}}</p>
          <p class="text-muted mb-0 font-size-15 wc-subtitle">
            {{'features7.carousel3Sub' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-block-quote8></app-block-quote8>

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/ar-ap-aging-analysis.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4">
          <b><span class="text-primary">AR & AP</span> Aging Analysis</b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.aging1Bold'
                | translate}}</b> <br>
              {{'features7.aging1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.aging2Bold'
                | translate}}</b> <br>
              {{'features7.aging2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-5">
          <br>
          <img src="assets/images/ss-app/ar-ap-aging-analysis.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/ar-paid-ivo.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b><span class="text-primary">Paid Invoice</span>
            Report</b></h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.paid1Bold' |
                translate}}</b><br>
              {{'features7.paid1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.paid2Bold' |
                translate}}</b><br>
              {{'features7.paid2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <app-testimonial></app-testimonial> -->

<section class="section feather-bg-img" style="background-color: #f8f9fa">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/due-over-ivo.png" class="img-fluid d-block w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>Due & Overdue <span class="text-primary">Invoice</span></b>
          <br>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features7.overdue1Bold' | translate}}</b><br>
              {{'features7.overdue1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features7.overdue2Bold' | translate}}</b>
              {{'features7.overdue1' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/ss-app/due-over-ivo.png" class="img-fluid d-block w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/stt-of-acc.png" class="img-fluid d-block w-100">
        </div>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b><span class="text-primary">Statement</span> of Account</b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features7.statAcc1Bold' | translate}}</b><br>
              {{'features7.statAcc1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
              <b>{{'features7.statAcc2Bold' | translate}}</b> <br>
              {{'features7.statAcc2' | translate}}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/9.png)">
  <div class="container">
    <div class="row align-items-center">
      <div *ngIf="isMobile">
        <img src="assets/images/ss-app/arcn.png" class="w-100">
      </div>
      <div class="col-lg-5">
        <h3 class="font-weight-bold line-height-1_4 mb-4"><b>AR <span class="text-primary">Credit Note</span></b>
        </h3>
        <ul>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.ar1Bold' |
                translate}}</b><br>
              {{'features7.ar1' | translate}}
            </p>
          </li>
          <li>
            <p class="mb-2 font-size-15">
              <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather> <b>{{'features7.ar2Bold' |
                translate}}</b> <br>
              {{'features7.ar2' | translate}}
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 offset-lg-1" *ngIf="!isMobile">
        <div class="mt-3">
          <img src="assets/images/ss-app/arcn.png" class="w-100">
        </div>
      </div>
    </div>
  </div>
</section>

<app-ar-ap-collapse></app-ar-ap-collapse>