<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Software Implementation</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted font-size-17 mb-4">
              Proses implementasi software bukanlah hal yang sederhana, apalagi untuk sebuah software besar semisal ERP.
              Proses-nya akan melibatkan hampir semua divisi di perusahaan dan memerlukan tingkat kedisiplinan yang
              tinggi.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_in_progress_ql66.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-17 mb-4">
        Tidak sedikit kasus yang terjadi dimana perusahaan telah meng-investasikan uang yang besar untuk sebuah software
        ERP, namun gagal di-implementasikan organisasinya.
      </p>
      <p class="text-muted font-size-17 mb-4">
        Kami menerapkan metodelogi implementasi yang sudah teruji dan terbukti sukses mengantarkan banyak client kami
        beralih dari sistem manual menuju penggunaan software yang terintegrasi. Berikut adalah tahapan (roadmap) dalam
        melakukan implementasi software:
      </p>
      <h3 class="title mb-3">Project Preparation(persiapan Awal Project)</h3>
      <p class="text-muted font-size-15 mb-4">
        Project besar dan penting tentunya membutuhkan arahan jelas dan terperinci tentang tujuan utama yang akan
        dicapai . Team yang terlibat harus dipastikan memahami dengan terperinci perannya masing-masing.
        <br>
        Berikut ini adalah hal-hal yang harus dipastikan pada tahapan Project Preparation :
      </p>
      <ul class="text-muted font-size-15 mb-4">
        <li>Apa tujuan utama yang ingin dicapai dari project implementasi</li>
        <li>Apa ruang lingkup software yang akan digunakan</li>
        <li>Siapa leader dan team yang akan mengawal jalannya project, baik dari internal perusahaan dan juga dari
          penyedia software</li>
        <li>Siapa divisi dan orang-orang yang akan terlibat dalam penggunaan software ini</li>
        <li>Persiapan data apa saja yang diperlukan dan siapa penanggungjawab dari kelengkapan data yang diperlukan
          tersebut</li>
        <li>Bagaimana jadwal implementasi dan kapan target data dan software akan disiapkan dan digunakan secara
          langsung (go live)</li>
        <li>Bagaimana Persiapan perangkat pendukung untuk operasional software</li>
      </ul>

      <p class="text-muted font-size-15 mb-4">
        Output utama dari tahapan ini adalah “Project Kick Off Presentation” yang berisi arahan penting dan terperinci
        untuk project yang akan dijalankan. Setiap orang harus menyamakan persepsi dan memastikan memahami peran
        masing-masing untuk menjamin keberhasilan project.
      </p>

      <h3 class="title mb-3">Business Blueprint (Dokumentasi Proses Bisnis / SOP)</h3>
      <p class="text-muted font-size-15 mb-4">
        Pada tahapan penting ini, dilakukan diskusi detil (focus group discussion) terkait proses bisnis yang berjalan
        saat ini dan juga rencana perubahan proses bisnis di masa mendatang yang lebih baik. Tahapan ini juga membahas
        detil penyesuaian dan penambahan yang harus dilakukan pada sistem terhadap proses bisnis yang akan dijalankan.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Output yang harus dihasilkan dari tahapan ini adalah SOP detil dari masing-masing divisi perusahaan terkait
        proses bisnis yang telah disepakati dan akan dijalankan. Dokument blueprint tersebut haruslah difahami dengan
        baik, disosialisasikan ke semua pihak yang terlibat dan kemudian ditandatangani bersama untuk memastikan
        kesamaan persepsi.
      </p>

      <h3 class="title mb-3">Final Preparation (Persiapan Akhir Menuju Go Live)</h3>
      <p class="text-muted font-size-15 mb-4">
        Persiapan akhir merupakan tahapan untuk melakukan ujicoba sistem secara menyeluruh. Pihak penyedia software dan
        juga pihak pengguna (user) harus melakukan simulasi bersama secara detil terhadap SOP yang sudah disepakati
        menggunakan tools software yang telah diselesaikan.
        <br>
        Persiapan akhir meliputi hal-hal sebagai berikut :
      </p>

      <ul class="text-muted font-size-15 mb-4">
        <li>Ujicoba sistem sesuai dengan SOP yang disepakati</li>
        <li>Training ke semua pengguna software</li>
        <li>Persiapan semua data awal yang diperlukan(Cut Off)</li>
        <li>Persiapan semua perangkat yang diperlukan</li>
        <li>Serta hal lain yang diperlukan untuk berjalannya sistem</li>
      </ul>

      <p class="text-muted font-size-15 mb-4">
        Keberhasilan persiapan akhir menandakan organisasi siap untuk mulai menggunakan sistem baru.
      </p>

      <h3 class="title mb-3">Go Live and Support</h3>
      <p class="text-muted font-size-15 mb-4">
        Fase ini adalah tahapan akhir dimana sistem akan digunakan secara langsung untuk aktivitas perusahaan. Team
        pemantau baik dari internal perusahaan maupun eksternal harus memastikan semua elemen bekerja maksimal dalam
        menjalankan sistem secara langsung. Dari pemantauan langsung, dapat dilakukan optimalisasi kinerja sistem lebih
        nyata.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Team pengembang software terus melakukan problem solving atas permasalahan yang muncul, baik terkait konfigurasi
        sistem, perubahan master data, atau permasalahan terkait pencatatan transaksi. Team support juga dapat melakukan
        training ulang jika dirasa pengguna (user) yang terlibat belum memahami penggunaan software secara maksimal.
      </p>
    </div>
  </section>
</div>